var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-swipe',{attrs:{"autoplay":3000}},_vm._l((_vm.home.banners),function(item,index){return _c('van-swipe-item',{key:index},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.img}})])}),1),_c('div',{staticClass:"card"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['home.productCenter'])+" ")]),_c('span',{staticStyle:{"width":"80px","text-align":"right"}},[_c('router-link',{attrs:{"to":{
            path: '/product/small-module',
            query: { locale: _vm.$i18n.locale },
          }}},[_c('a',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(_vm.$t('message')['home.more']))])])],1)]),_c('van-grid',{attrs:{"column-num":2,"gutter":2}},_vm._l((_vm.productList),function(item){return _c('van-grid-item',{key:item.id,staticStyle:{"font-size":"0.8em"},on:{"click":function($event){return _vm.gotoDetail(item)}}},[_c('van-image',{staticStyle:{"min-width":"80px","margin-bottom":"5px"},attrs:{"fit":"contain","lazy-load":"","src":_vm.$i18n.locale === 'enUS' ? item.imageUrlEn : item.imageUrl,"alt":_vm.$i18n.locale === 'enUS' ? item.nameEn : item.name}}),_vm._v(" "+_vm._s(_vm.$i18n.locale === 'enUS' ? item.nameEn : item.name)+" ")],1)}),1)],1),_c('div',{staticClass:"card"},[_c('div',{staticStyle:{"display":"flex","padding-top":"20px"}},[_c('span',{staticClass:"title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['home.companyIntroduce'])+" ")]),_c('span',{staticStyle:{"width":"80px","text-align":"right"}},[_c('router-link',{attrs:{"to":{
            path: '/about-us/company',
            query: { locale: _vm.$i18n.locale },
          }}},[_c('a',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(_vm.$t('message')['home.more']))])])],1)]),_c('div',{staticClass:"company-box"},[_vm._l((_vm.company.introduce),function(item){return _c('p',{key:item,staticClass:"content-text"},[_vm._v(" "+_vm._s(item)+" ")])}),_c('p',{staticClass:"content-text"},[_vm._v(" "+_vm._s(_vm.$t('message')['home.productFeature'])+" ")]),_vm._l((_vm.company.feature),function(item){return _c('p',{key:item,staticClass:"content-text"},[_vm._v(" "+_vm._s(item)+" ")])})],2)]),_c('div',{staticClass:"card"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['home.cooperationScheme'])+" ")]),_c('span',{staticStyle:{"width":"80px","text-align":"right"}},[_c('router-link',{attrs:{"to":{
            path: '/about-us/cooperation',
            query: { locale: _vm.$i18n.locale },
          }}},[_c('a',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(_vm.$t('message')['home.more']))])])],1)]),_c('van-grid',{attrs:{"column-num":2,"gutter":2}},_vm._l((_vm.cooperation),function(item){return _c('van-grid-item',{key:item.title,staticStyle:{"font-size":"0.8em"}},[_c('van-image',{attrs:{"height":"90px","fit":"contain","src":item.img,"alt":item.title}}),_vm._v(" "+_vm._s(item.title)+" ")],1)}),1)],1),_c('div',{staticClass:"card"},[_c('div',{staticStyle:{"display":"flex","padding-top":"20px"}},[_c('span',{staticClass:"title",staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.$t('message')['home.serviceFeatures'])+" ")])]),_c('div',{staticClass:"company-box"},_vm._l((_vm.serviceFeature.feature),function(item,index){return _c('div',{key:item.title,staticStyle:{"color":"#4d4d4d","font-size":"0.9em"}},[_c('span',{staticStyle:{"font-size":"1em","font-weight":"500"}},[_vm._v(" "+_vm._s(index + 1)+"、 "+_vm._s(item.title)+"： ")]),_c('p',{staticStyle:{"margin-left":"30px"}},[_vm._v(_vm._s(item.content))])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }