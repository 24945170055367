import request from '@/utils/request'

const productApi = {
  HomeProductList: '/api/web/home/product/list',
  ProductList: '/api/web/product/list',
  NordicProductList: '/api/web/nordic/product/list',
  DialogProductList: '/api/web/dialog/product/list',
  ProductDetail: '/api/web/product/detail',
}

// 获取首页产品列表
export function getHomeProductList() {
  return request({
    url: productApi.HomeProductList,
    method: 'post',
    data: {},
  })
}

// 获取产品列表
export function getProductList() {
  return request({
    url: productApi.ProductList,
    method: 'post',
    data: {},
  })
}

// 获取Nordic产品列表
export function getNordicProductList() {
  return request({
    url: productApi.NordicProductList,
    method: 'post',
    data: {},
  })
}

// 获取Dialog产品列表
export function getDialogProductList() {
  return request({
    url: productApi.DialogProductList,
    method: 'post',
    data: {},
  })
}

// 获取产品详情
export function getProductDetail(id) {
  return request({
    url: productApi.ProductDetail,
    method: 'post',
    data: { id },
  })
}
