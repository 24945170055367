<template>
  <div>
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(item, index) in home.banners" :key="index">
        <img style="width: 100%" :src="item.img" />
      </van-swipe-item>
    </van-swipe>

    <!-- 产品 -->
    <div class="card">
      <div style="display: flex">
        <span class="title" style="flex: 1">
          {{ $t('message')['home.productCenter'] }}
        </span>
        <span style="width: 80px; text-align: right">
          <router-link
            :to="{
              path: '/product/small-module',
              query: { locale: $i18n.locale },
            }"
          >
            <a style="color: #1890ff">{{ $t('message')['home.more'] }}</a>
          </router-link>
        </span>
      </div>
      <van-grid :column-num="2" :gutter="2">
        <van-grid-item
          v-for="item in productList"
          :key="item.id"
          style="font-size: 0.8em"
          @click="gotoDetail(item)"
        >
          <van-image
            style="min-width: 80px; margin-bottom: 5px"
            fit="contain"
            lazy-load
            :src="$i18n.locale === 'enUS' ? item.imageUrlEn : item.imageUrl"
            :alt="$i18n.locale === 'enUS' ? item.nameEn : item.name"
          />
          {{ $i18n.locale === 'enUS' ? item.nameEn : item.name }}
        </van-grid-item>
      </van-grid>
    </div>

    <!-- 公司介绍 -->
    <div class="card">
      <div style="display: flex; padding-top: 20px">
        <span class="title" style="flex: 1">
          {{ $t('message')['home.companyIntroduce'] }}
        </span>
        <span style="width: 80px; text-align: right">
          <router-link
            :to="{
              path: '/about-us/company',
              query: { locale: $i18n.locale },
            }"
          >
            <a style="color: #1890ff">{{ $t('message')['home.more'] }}</a>
          </router-link>
        </span>
      </div>

      <div class="company-box">
        <p v-for="item in company.introduce" :key="item" class="content-text">
          {{ item }}
        </p>

        <p class="content-text">
          {{ $t('message')['home.productFeature'] }}
        </p>

        <p v-for="item in company.feature" :key="item" class="content-text">
          {{ item }}
        </p>
      </div>
    </div>

    <!-- 合作方 -->
    <div class="card">
      <div style="display: flex">
        <span class="title" style="flex: 1">
          {{ $t('message')['home.cooperationScheme'] }}
        </span>
        <span style="width: 80px; text-align: right">
          <router-link
            :to="{
              path: '/about-us/cooperation',
              query: { locale: $i18n.locale },
            }"
          >
            <a style="color: #1890ff">{{ $t('message')['home.more'] }}</a>
          </router-link>
        </span>
      </div>
      <van-grid :column-num="2" :gutter="2">
        <van-grid-item
          v-for="item in cooperation"
          :key="item.title"
          style="font-size: 0.8em"
        >
          <van-image
            height="90px"
            fit="contain"
            :src="item.img"
            :alt="item.title"
          />
          {{ item.title }}
        </van-grid-item>
      </van-grid>
    </div>

    <div class="card">
      <div style="display: flex; padding-top: 20px">
        <span class="title" style="flex: 1">
          {{ $t('message')['home.serviceFeatures'] }}
        </span>
      </div>

      <div class="company-box">
        <div
          style="color: #4d4d4d; font-size: 0.9em"
          v-for="(item, index) in serviceFeature.feature"
          :key="item.title"
        >
          <span style="font-size: 1em; font-weight: 500">
            {{ index + 1 }}、 {{ item.title }}：
          </span>
          <p style="margin-left: 30px">{{ item.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
import { getHomeProductList } from '@/api/product'
export default {
  watch: {
    '$i18n.locale': function (val) {
      this.changeLanguage(val)
    },
  },
  data() {
    return {
      company: dataZH.company,
      home: dataZH.home,
      cooperation: dataZH.cooperation.slice(0, 4),
      serviceFeature: dataZH.serviceFeature,
      productList: [],
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
    this.getProductList()
  },
  methods: {
    // 获取产品列表
    getProductList() {
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      })
      getHomeProductList()
        .then((res) => {
          if (res.code === 200) {
            this.productList = res.data
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$toast.fail(err.message)
          }
        })
        .finally(() => {
          toast.clear()
        })
    },
    // 更改语言
    changeLanguage(language) {
      this.company = language === 'enUS' ? dataEN.company : dataZH.company
      this.home = language === 'enUS' ? dataEN.home : dataZH.home
      this.serviceFeature =
        language === 'enUS' ? dataEN.serviceFeature : dataZH.serviceFeature
      let cooperation =
        language === 'enUS' ? dataEN.cooperation : dataZH.cooperation
      this.cooperation = cooperation.slice(0, 4)
    },

    gotoDetail(item) {
      this.$router.push({ path: '/product/small-module/detail/' + item.id })
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  padding: 15px 5px 0px 5px;
}

.title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}

.company-box {
  padding: 0px 20px 20px 20px;
}
</style>
